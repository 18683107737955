import React, {useState, useEffect} from 'react';
import { Button, TextField, InputLabel, Select, OutlinedInput, MenuItem, FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ru } from 'date-fns/locale';
import './Expenses.css'
import { useTelegram } from "../../hooks/useTelegram";
import axios from 'axios';

const url = process.env.REACT_APP_SERVER_URL;

const Expenses = () => {

    const { tg, user }  = useTelegram();

    const [Data, setData] = useState({
        Category: 1,
        Comment: '',
        Date: new Date(),
        Summa: '',
    })

    const buttonSave = tg.MainButton.setParams({
        text: 'Записать расходы'
    })
    
    if(Data.Summa) {
        buttonSave.show()
    }

    const categories = [
        {id: 1, title: 'Продукты'},
        {id: 2, title: 'Одежда'},
        {id: 3, title: 'Коммунальные услуги'},
        {id: 4, title: 'Проезд'},
        {id: 5, title: 'Прочее'},
    ]

    const newExpenses = () => {
        console.log(user)
        buttonSave.hide();

        axios.post(`${url}/expenses`, {Data, user})
        .then(response => {
            console.log('response', response)
            let requestData = {
                method: 'get',
                url: `https://api.telegram.org/bot${process.env.REACT_APP_TG_TOKEN}/sendMessage`,
                params: {
                    disable_web_page_preview: true,
                    chat_id: user.id,
                    text: `Ваши расходы ${JSON.stringify(Data)} записаны успешно`,                
                }
            };
            axios(requestData)
            .then( data => tg.close())
            .catch(error => console.log(error))
        })
        .catch(error => {
            let requestData = {
                method: 'get',
                url: `https://api.telegram.org/bot${process.env.REACT_APP_TG_TOKEN}/sendMessage`,
                params: {
                    disable_web_page_preview: true,
                    chat_id: user.id,
                    text: `Ваши расходы ${JSON.stringify({Data, user})} не записаны`,                
                }
            };
            axios(requestData)
            .then( data => tg.close())
            .catch(error => console.log(error))
            console.log('error', error)
        })
    }

    useEffect(() => {
        tg.onEvent('mainButtonClicked', newExpenses)
        return () => {
            tg.offEvent('mainButtonClicked', newExpenses)
        }
    }, [newExpenses])

    const handleSummaChange = (e) => {
        const value = e.target.value;
        // Регулярное выражение для разрешения только цифр, запятых и точек
        const regex = /^[0-9.,]*$/;

        if (regex.test(value)) {
            setData({ ...Data, Summa: value });
        }
    };

    return (
       <div>
            <h3 className='FormExpenses'>Форма заполнения расхода</h3>

            <FormControl sx={{ m: 1}}>
                <InputLabel id="demo-multiple-name-label">Категория</InputLabel>
                <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={Data.Category}
                onChange={(e) => setData({...Data, Category: e.target.value})}
                input={<OutlinedInput label="Name" />}
                >
                {categories.map((el) => (
                    <MenuItem
                    key={el.id}
                    value={el.id}
                    >
                    {el.title}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>

            <TextField onChange={(e) => setData({...Data, Comment: e.target.value})} name="Comment" value={Data.Comment}
                sx={{margin: '10px'}}
                label="Комментарий" className="outlined-size-normal" 
                placeholder="Комментарий"
            />

            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
                <DatePicker
                    label="Дата"
                    value={Data.Date}
                    onChange={(newValue) => setData({ ...Data, Date: newValue })}
                    renderInput={(params) => <TextField {...params} sx={{ margin: '10px', width: '50vw' }} />}
                    inputFormat="dd/MM/yyyy"
                />
            </LocalizationProvider>

            <TextField onChange={handleSummaChange} name="Summa" value={Data.Summa}
                sx={{margin: '10px'}}
                label="Сумма" className="outlined-size-normal" 
                placeholder="Сумма"
            />

       </div>
    );
};

export default Expenses;