import React, {useState} from 'react';
import {Button} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const url = process.env.REACT_APP_SERVER_URL;

const Main = () => {
    let navigate = useNavigate();

    return (
       <div>
            Main
       </div>
    );
};

export default Main;