import React, {useState} from 'react';
import {Button} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const url = process.env.REACT_APP_SERVER_URL;

const Statistics = () => {

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [err, setErr] = useState('');
    let navigate = useNavigate();


    return (
       <div>
            Statistics
       </div>
    );
};

export default Statistics;