import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTelegram } from "../../hooks/useTelegram";
import { Button, TextField, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
const url = process.env.REACT_APP_SERVER_URL;

const CategoryExpenses = () => {
    const { tg, user }  = useTelegram();

    const buttonSave = tg.MainButton.setParams({
        text: 'Сохранить и закрыть окно'
    })
    const categoriesExpenses01 = [
        {id: 1, title: 'Продукты'},
        {id: 2, title: 'Одежда'},
        {id: 10, title: 'Коммунальные услуги'},
        {id: 56, title: 'Проезд'},
    ]
    const [categoriesExpenses, setCategoriesExpenses] = useState(categoriesExpenses01)
    const [newCategory, setNewCategory] = useState('');

    const handleAddCategory = () => {
        const newId = categoriesExpenses.length ? Number(categoriesExpenses[categoriesExpenses.length - 1].id.toString().replace('id', '')) + 1 : 1;
        const newCategoryObj = { id: `id${newId}`, title: newCategory };
        setCategoriesExpenses([...categoriesExpenses, newCategoryObj]);
        setNewCategory('');
    };

    const handleDeleteCategory = (id) => {
        setCategoriesExpenses(categoriesExpenses.filter(category => category.id !== id));
    };

    useEffect(() => {
        axios.get(`${url}/category/expenses/${user.id}`)
        .then((response) => {
            setCategoriesExpenses(response.data.category)
            buttonSave.show()
        })
        .catch(error => {
            console.log(error)
        })
    }, [])

    const saveCategory = () => {
        buttonSave.hide();

        axios.post(`${url}/category/expenses`, {categoriesExpenses, user})
        .then(response => {
            console.log('response', response)
            let requestData = {
                method: 'get',
                url: `https://api.telegram.org/bot${process.env.REACT_APP_TG_TOKEN}/sendMessage`,
                params: {
                    disable_web_page_preview: true,
                    chat_id: user.id,
                    text: `Ваши категории расходов ${JSON.stringify(categoriesExpenses)} сохранены успешно`,                
                }
            };
            axios(requestData)
            .then( data => tg.close())
            .catch(error => console.log(error))
        })
        .catch(error => {
            let requestData = {
                method: 'get',
                url: `https://api.telegram.org/bot${process.env.REACT_APP_TG_TOKEN}/sendMessage`,
                params: {
                    disable_web_page_preview: true,
                    chat_id: user.id,
                    text: `Ваши категории расходов ${JSON.stringify({categoriesExpenses, user})} не сохранены`,                
                }
            };
            axios(requestData)
            .then( data => tg.close())
            .catch(error => console.log(error))
            console.log('error', error)
        })
    }

    useEffect(() => {
        tg.onEvent('mainButtonClicked', saveCategory)
        return () => {
            tg.offEvent('mainButtonClicked', saveCategory)
        }
    }, [saveCategory])

    return (
        <div>
            <div>
                <h3>Редактировать категории расходов</h3>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                    <TextField
                        label="Новая категория"
                        variant="outlined"
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                        style={{ flexGrow: 1, marginRight: '1rem' }}
                    />
                    <IconButton color="primary" onClick={handleAddCategory}>
                        <SaveIcon />
                    </IconButton>
                </div>

                <List>
                {categoriesExpenses.map((category) => (
                    <ListItem key={category.id}>
                        <ListItemText primary={category.title} />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCategory(category.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            </div>
        </div>
    );
};

export default CategoryExpenses;