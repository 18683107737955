import './App.css';
import {useEffect} from "react";
import { Routes, Route } from 'react-router-dom';
import Expenses from './components/Expenses/Expenses';
import {useTelegram} from "./hooks/useTelegram";
import Income from './components/Income/Income';
import Statistics from './components/Statistics/Statistics';
import Main from './components/Main/Main';
import CategoryIncome from './components/CategoryIncome/CategoryIncome';
import CategoryExpenses from './components/CategoryExpenses/CategoryExpenses';

function App() {
  const { tg, user } = useTelegram();

  useEffect(() => {
      tg.ready();
      localStorage.setItem('user', user);
  }, [])

  return (
    <div className="App">
        <Routes>
            <Route path="/" element={<Main/>}/>
            <Route path="/expenses" element={<Expenses/>}/>
            <Route path="/income" element={<Income/>}/>
            <Route path="/statistics" element={<Statistics/>}/>
            <Route path="/categoryIncome" element={<CategoryIncome/>}/>
            <Route path="/categoryExpenses" element={<CategoryExpenses/>}/>
            <Route path="/*" element={<Main/>}/>
        </Routes>

  </div>
  );
}

export default App;
